<script setup lang="ts">
import { useForm } from "vee-validate";
import { useAuthStore } from "@/store/auth";
import OAuthButtons from "@/components/auth/OAuthButtons.vue";
import { OAUTH_METHOD } from "@/constants";

interface Emit {
  (e: "verify-email", email: string): void;
}

const emit = defineEmits<Emit>();
const email = ref("");
const password = ref("");
const rememberMe = ref(false);
const authStore = useAuthStore();
const { handleSubmit, isSubmitting } = useForm();

const login = handleSubmit(async () => {
  const { errorMessage } = await authStore.signIn(email.value, password.value);
  if (!errorMessage) return navigateTo(ROUTES.confirm);
  if (errorMessage === ERROR_MESSAGES.email_not_confirmed) return emit("verify-email", email.value);
  return showNotifyError(errorMessage);});
</script>

<template>
  <Card class="relative w-full border-1 border-light-gray">
    <div class="mb-4 text-center">
      <h4 class="mb-4">Employer Login</h4>
      <p>Welcome back, please login to your account</p>
    </div>

    <form @keyup.enter="login" @submit="login">
      <FormInput
        v-model="email"
        :disabled="isSubmitting"
        type="email"
        name="email"
        icon="fa6-solid:envelope"
        validate-as="email"
        placeholder="Please enter your email"
        label="Email"
        class="w-full"
        autofocus
      />

      <FormInput
        v-model="password"
        :disabled="isSubmitting"
        type="password"
        name="password"
        icon="fa6-solid:lock"
        validate-as="password"
        placeholder="Please enter your password"
        label="Password"
        class="mb-1 mt-3 w-full"
      />

      <NuxtLink :to="ROUTES.forgotPassword" class="link text-sm"> Forgot Password? </NuxtLink>

      <label class="my-5 flex items-center">
        <Checkbox v-model:checked="rememberMe" />
        <span class="ml-2 mt-px text-sm">Remember me</span>
      </label>

      <PrimaryButton :disabled="isSubmitting" :spinner="isSubmitting" class="w-full" type="submit"> Sign in </PrimaryButton>
    </form>

    <OAuthButtons :method="OAUTH_METHOD.login" />
    <p class="mt-5 text-center text-sm">
      Don't have an account?

      <NuxtLink :to="ROUTES.register" class="link"> Sign up </NuxtLink>
    </p>
  </Card>
</template>
